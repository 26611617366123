@import url("https://fonts.googleapis.com/css2?family=Bayon&family=Work+Sans:wght@400;500;600&display=swap");

* {
  outline: none;
}

html {
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Bayon", sans-serif;
  overflow-x: hidden;
}

a,
span {
  font-weight: 400;
}

img {
  max-width: 100%;
  height: auto;
}

p,
ul {
  margin: 0;
  padding: 0;
}

.landing-bg {
  background-image: url("../assets/images/desktop-bg.webp");
  min-height: 100vh;
  padding: 20px 0px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.subscrption-content {
  max-width: 375px;
  margin: auto;
  padding: 0 10px;
  text-align: center;
}
p.error {
  color: red !important;
  font-weight: 300 !important;
  font-size: 14px !important;
  margin-top: 10px !important;
  text-align: center !important;
}
.disable-btn {
  opacity: 0.4 !important;
  cursor: default !important;
}

.subscrption-content .main-text {
  padding: 15px 0px;
}
.subscrption-content .main-img img {
  transform: scale(1.1);
  margin-bottom: 5px;
}

.subscrption-content .p-text {
  font-size: 30px;
  line-height: 30px;
}

.subscrption-content .p-text span {
  color: #1cb4e5;
}

.subscrption-content .button .p-button {
  color: #fff;
  background: #ffaf00;
  text-decoration: none;
  font-weight: 600;
  width: 100%;
  padding: 18px 20px;
  border: none;
  font-size: 18px;
  cursor: pointer;
  margin: 15px 0px;
}
.subscrption-content a.term {
  color: rgba(0, 37, 49, 1);
}

.subscrption-content .sub-text {
  color: rgba(0, 37, 49, 0.8);
  font-size: 13px;
  margin: auto;
  padding: 0 10px;
  line-height: 15px;
}

@media (max-width: 767px) {
  .landing-bg {
    background-image: url("../assets/images/mobie-bg.webp");
  }
}
